
// Note: Some of these descriptions here are more specific than the name would suggest, because we
// only use them in one place. However, this shouldn't prevent us from using the error elsewhere if
// the name fits, and we should update the description to be more general when this happens.

module.exports = {
  // The API key does not match the token or session.
  AUTHENTICATION_ERROR: 'OT_AUTHENTICATION_ERROR',

  // JSON response was badly formed.
  BADLY_FORMED_RESPONSE: 'OT_BADLY_FORMED_RESPONSE',

  // Unable to publish because your browser failed to get access to your microphone. You may need to
  // fully quit and restart your browser to get it to work.
  // See https://bugs.chromium.org/p/webrtc/issues/detail?id=4799 for more details.
  CHROME_MICROPHONE_ACQUISITION_ERROR: 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR',

  // Failed to connect to session.
  CONNECT_FAILED: 'OT_CONNECT_FAILED',

  // The session has exceded the maximum number of simultaneous connections.
  CONNECTION_LIMIT_EXCEEDED: 'OT_CONNECTION_LIMIT_EXCEEDED',

  // The constraints for getting user media could not be satisfied.
  CONSTRAINTS_NOT_SATISFIED: 'OT_CONSTRAINTS_NOT_SATISFIED',

  // Failed to create peer connection.
  CREATE_PEER_CONNECTION_FAILED: 'OT_CREATE_PEER_CONNECTION_FAILED',

  // Action failed due to session disconnection.
  DISCONNECTED: 'OT_DISCONNECTED',

  // Received an unexpected empty response from the server.
  EMPTY_RESPONSE_BODY: 'OT_EMPTY_RESPONSE_BODY',

  // The hardware to fulfil the user media request appears to exist but could not be acquired. It
  // might be in use by another application.
  HARDWARE_UNAVAILABLE: 'OT_HARDWARE_UNAVAILABLE',

  // Something went wrong while establishing WebRTC connectivity.
  ICE_WORKFLOW_FAILED: 'OT_ICE_WORKFLOW_FAILED',

  // Received an invalid HTTP status. This may be considered invalid for the endpoint only and not
  // HTTP in general.
  INVALID_HTTP_STATUS: 'OT_INVALID_HTTP_STATUS',

  // One or more parameters was not valid or not provided.
  INVALID_PARAMETER: 'OT_INVALID_PARAMETER',

  // The session id was invalid.
  INVALID_SESSION_ID: 'OT_INVALID_SESSION_ID',

  // The fetching of the stream for the video element has been aborted.
  MEDIA_ERR_ABORTED: 'OT_MEDIA_ERR_ABORTED',

  // A decoding error occurred while trying to play the stream in the video element.
  MEDIA_ERR_DECODE: 'OT_MEDIA_ERR_DECODE',

  // A network error caused the stream to stop being fetched.
  MEDIA_ERR_NETWORK: 'OT_MEDIA_ERR_NETWORK',

  // The stream has been detected to be not suitable for playback.
  MEDIA_ERR_SRC_NOT_SUPPORTED: 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED',

  // The ended event on the video element fired
  MEDIA_ENDED: 'OT_MEDIA_ENDED',

  // No devices were found to provide the media stream.
  NO_DEVICES_FOUND: 'OT_NO_DEVICES_FOUND',

  // Video and audio was disabled. You need to enable at least one.
  NO_VALID_CONSTRAINTS: 'OT_NO_VALID_CONSTRAINTS',

  // Couldn't perform action due to not being connected.
  NOT_CONNECTED: 'OT_NOT_CONNECTED',

  // A resource was not found.
  NOT_FOUND: 'OT_NOT_FOUND',

  // Something in the user media request is not supported.
  NOT_SUPPORTED: 'OT_NOT_SUPPORTED',

  // An action was not permitted.
  PERMISSION_DENIED: 'OT_PERMISSION_DENIED',

  // The signal could not be sent due to the rate limit.
  RATE_LIMIT_EXCEEDED: 'OT_RATE_LIMIT_EXCEEDED',

  // Error calling OT.reportIssue(). Check the client\'s network connection.
  REPORT_ISSUE_FAILED: 'OT_REPORT_ISSUE_FAILED',

  // Screen-sharing support in this browser requires an extension, but the extension is not
  // installed.
  SCREEN_SHARING_EXTENSION_NOT_INSTALLED: 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED',

  // Screen-sharing support in this browser requires an extension, but one has not been registered.
  SCREEN_SHARING_EXTENSION_NOT_REGISTERED: 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED',

  // Screen sharing is not supported in the browser.
  SCREEN_SHARING_NOT_SUPPORTED: 'OT_SCREEN_SHARING_NOT_SUPPORTED',

  // The WebRTC connection failed during setDescription.
  SET_REMOTE_DESCRIPTION_FAILED: 'OT_SET_REMOTE_DESCRIPTION_FAILED',

  // Rumor.Socket cannot connect when it is already connecting or connected.
  SOCKET_ALREADY_CONNECTED_CONNECTING: 'OT_SOCKET_ALREADY_CONNECTED_CONNECTING',

  // A connection was closed abnormally (that is, with no close frame being sent) when a status code
  // is expected.
  SOCKET_CLOSE_ABNORMAL: 'OT_SOCKET_CLOSE_ABNORMAL',

  // Exception was thrown during Rumor connection, possibly because of a blocked port.
  SOCKET_CLOSE_CONNECT_EXCEPTION: 'OT_SOCKET_CLOSE_CONNECT_EXCEPTION',

  // Connectivity loss was detected as it was too long since the socket received the last PONG
  // message.
  SOCKET_CLOSE_CONNECTIVITY_LOSS: 'OT_SOCKET_CLOSE_CONNECTIVITY_LOSS',

  // Falling back on this error for socket close because a more suitable one was not found.
  SOCKET_CLOSE_FALLBACK_CODE: 'OT_SOCKET_CLOSE_FALLBACK_CODE',

  // An endpoint received data within a message that was not consistent with the type of the message
  // (e.g., non-UTF-8 [RFC3629] data within a text message)
  SOCKET_CLOSE_INCONSISTENT_DATA: 'OT_SOCKET_CLOSE_INCONSISTENT_DATA',

  // No status code was provided even though one was expected.
  SOCKET_CLOSE_NO_STATUS: 'OT_SOCKET_CLOSE_NO_STATUS',

  // An endpoint received a message that violates its policy.  This is a generic status code that
  // can be returned when there is no other more suitable status code (e.g., 1003 or 1009) or if
  // there is a need to hide specific details about the policy.
  SOCKET_CLOSE_POLICY_VIOLATION: 'OT_SOCKET_CLOSE_POLICY_VIOLATION',

  // The endpoint is going away, either because of a server failure or because the browser is
  // navigating away from the page that opened the connection.
  SOCKET_CLOSE_GOING_AWAY: 'OT_SOCKET_CLOSE_GOING_AWAY',

  // A protocol error occurred.
  SOCKET_CLOSE_PROTOCOL_ERROR: 'OT_SOCKET_CLOSE_PROTOCOL_ERROR',

  // Timed out while waiting for the Rumor socket to connect.
  SOCKET_CLOSE_TIMEOUT: 'OT_SOCKET_CLOSE_TIMEOUT',

  // An endpoint received a message that is too big for it to process
  SOCKET_CLOSE_TOO_LARGE: 'OT_SOCKET_CLOSE_TOO_LARGE',

  // An endpoint encountered an unexpected condition that prevented it from fulfilling the request.
  SOCKET_CLOSE_UNEXPECTED_CONDITION: 'OT_SOCKET_CLOSE_UNEXPECTED_CONDITION',

  // An endpoint has indicated that reconnections are not available.
  SOCKET_CLOSE_UNSUPPORTED: 'OT_SOCKET_CLOSE_UNSUPPORTED',

  // Failed to create the stream in the server model.
  STREAM_CREATE_FAILED: 'OT_STREAM_CREATE_FAILED',

  // The stream was destroyed before it could be subscribed to.
  STREAM_DESTROYED: 'OT_STREAM_DESTROYED',

  // The session has exceded the maximum number of simultaneous streams.
  STREAM_LIMIT_EXCEEDED: 'OT_STREAM_LIMIT_EXCEEDED',

  // Couldn't subscribe to the stream because it was not found.
  STREAM_NOT_FOUND: 'OT_STREAM_NOT_FOUND',

  // Couldn't connect due to a terms of service violation.
  TERMS_OF_SERVICE_FAILURE: 'OT_TERMS_OF_SERVICE_FAILURE',

  // A timer expired while waiting for an action to occur.
  TIMEOUT: 'OT_TIMEOUT',

  // The operation was cancelled
  CANCEL: 'OT_CANCEL',

  // Generic media abort error
  UNABLE_TO_CAPTURE_MEDIA: 'OT_UNABLE_TO_CAPTURE_MEDIA',

  // Generic/ Unknown screen share error
  UNABLE_TO_CAPTURE_SCREEN: 'OT_UNABLE_TO_CAPTURE_SCREEN',

  // The error code received was unexpected.
  UNEXPECTED_ERROR_CODE: 'OT_UNEXPECTED_ERROR_CODE',

  // Received an unexpected HTTP status.
  UNEXPECTED_HTTP_STATUS: 'OT_UNEXPECTED_HTTP_STATUS',

  // An unexpected server response was received which could not be handled.
  UNEXPECTED_SERVER_RESPONSE: 'OT_UNEXPECTED_SERVER_RESPONSE',

  // An unknown anvil error occurred.
  UNKNOWN_HTTP_ERROR: 'OT_UNKNOWN_HTTP_ERROR',

  UNSUPPORTED_BROWSER: 'OT_UNSUPPORTED_BROWSER',

  // Access to user media was denied.
  USER_MEDIA_ACCESS_DENIED: 'OT_USER_MEDIA_ACCESS_DENIED',

  // There was a cross domain error or the server responded with invalid JSON.
  XDOMAIN_OR_PARSING_ERROR: 'OT_XDOMAIN_OR_PARSING_ERROR',

  API_KEY_DISABLED: 'OT_API_KEY_DISABLED',

  // There was an error when trying to get the list of supported codecs.
  UNABLE_TO_ACCESS_MEDIA_ENGINE: 'OT_UNABLE_TO_ACCESS_MEDIA_ENGINE',
};
