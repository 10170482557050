const { normaliseOptions } = require('./normaliseOptions');
const { isMediaStreamTrack } = require('./isMediaStreamTrack');

/* eslint-disable global-require */

const assert = require('assert');
const assign = require('lodash/assign');
const defaults = require('lodash/defaults');
const promiseEndeavour = require('promise-endeavour').default;
const Errors = require('../Errors');
const pick = require('lodash/pick');
const otError = require('../../helpers/otError');

const eventing = require('../../helpers/eventing.js');
const isScreenSharingSource = require('./isScreenSharingSource');
const env = require('../../helpers/env');


const GET_USER_ABORT_ERROR_MSG = 'getUserMedia request was aborted';

module.exports = function processPubOptionsFactory(deps = {}) {
  [
    'deviceHelpers',
    'getUserMediaHelper',
  ].forEach((key) => {
    assert(deps[key], `${key} dependency must be injected into processPubOptions`);
  });

  const generateConstraintInfo = deps.generateConstraintInfo || require('./generateConstraintInfo.js');
  const windowMock = deps.global || global;
  const logging = deps.logging || require('../../helpers/log')('processPubOptions');
  const navigator = deps.navigator || windowMock.navigator;
  const screenSharing = deps.screenSharing || require('../screensharing/screen_sharing.js')();
  const isiOS = deps.isiOS || require('../../helpers/isiOS');
  const usingOptionalMandatoryStyle = require('./usingOptionalMandatoryStyle')({ navigator: navigator || {} });

  const {
    deviceHelpers,
    getUserMediaHelper,
  } = deps;

  return (originalOptions, logPrefix, shouldStop) => {
    const options = normaliseOptions(originalOptions, logPrefix, logging);

    const isScreenSharing = isScreenSharingSource(options.videoSource);
    const isCustomAudioTrack = isMediaStreamTrack(options.audioSource);
    const isCustomVideoTrack = isMediaStreamTrack(options.videoSource);

    // getUserMedia is required if either audio or video is: a) not custom and b) not disabled
    const isAudioGumRequired = !isCustomAudioTrack && options.audioSource !== null;
    const isVideoGumRequired = !isCustomVideoTrack && options.videoSource !== null;
    const isGumRequired = isAudioGumRequired || isVideoGumRequired;

    // We display audio level and allow audio fallback if we're not screensharing
    // or if we're screensharing but with a custom audio track
    // @todo shouldn't this take into consideration if we have audio as well?
    const shouldAllowAudio = !isScreenSharing || isCustomAudioTrack;

    const properties = defaults(options, {
      mirror: !isScreenSharing && !isCustomVideoTrack,
      publishAudio: true,
      publishVideo: true,
      showControls: true,
      fitMode: isScreenSharing ? 'contain' : 'cover',
      audioFallbackEnabled: shouldAllowAudio,
      insertDefaultUI: true,
      enableRenegotiation: false,
      enableStereo: false,
      disableAudioProcessing: false,
    });

    if (properties.name) {
      properties.name = String(properties.name);
    }

    if (!properties.constraints) {
      const constraintInfo = generateConstraintInfo({
        isScreenSharing,
        isCustomAudioTrack,
        isCustomVideoTrack,
        ...pick(properties, [
          'audioSource',
          'publishAudio',
          'videoSource',
          'publishVideo',
          'resolution',
          'maxResolution',
          'frameRate',
          'facingMode',
          'enableRenegotiation',
          'enableStereo',
          'disableAudioProcessing',
        ]),
        env,
        usingOptionalMandatoryStyle: usingOptionalMandatoryStyle(isScreenSharing),
      });
      assign(properties, constraintInfo);
    } else {
      logging.warn(`${logPrefix}: You have passed your own constraints not using ours`);
    }

    const processedOptions = {
      isScreenSharing,
      isCustomAudioTrack,
      isCustomVideoTrack,
      shouldAllowAudio,
      properties,
    };

    eventing(processedOptions);

    processedOptions.getUserMedia = async () => {
      const onAccessDialogOpened = (...args) => processedOptions.emit('accessDialogOpened', ...args);
      const onAccessDialogClosed = (...args) => processedOptions.emit('accessDialogClosed', ...args);

      if (!isGumRequired) {
        const stream = new windowMock.MediaStream();
        if (isCustomAudioTrack) {
          stream.addTrack(options.audioSource);
        }
        if (isCustomVideoTrack) {
          stream.addTrack(options.videoSource);
        }
        return stream;
      }

      if (isScreenSharing) {
        properties.constraints = await screenSharing.getConstraints({
          onAccessDialogOpened,
          onAccessDialogClosed,
          videoSource: options.videoSource,
          constraints: properties.constraints,
        });
        if (shouldStop()) {
          throw otError(Errors.CANCEL, new Error(GET_USER_ABORT_ERROR_MSG));
        }
      } else {
        const devices = await deviceHelpers.shouldAskForDevices();

        if (shouldStop()) {
          throw otError(Errors.CANCEL, new Error(GET_USER_ABORT_ERROR_MSG));
        }

        logging.debug(`${logPrefix}: shouldAskForDevices:`, devices);

        if (!devices.video) {
          logging.warn(`${logPrefix}: Setting video constraint to false, there are no video sources`);
          properties.constraints.video = false;
        }

        if (!devices.audio) {
          logging.warn(`${logPrefix}: Setting audio constraint to false, there are no audio sources`);
          properties.constraints.audio = false;
        }

        // @todo this side effect needs to go...
        processedOptions.videoDevices = devices.videoDevices;
        processedOptions.audioDevices = devices.audioDevices;
      }

      const { constraints } = properties;
      logging.debug(`${logPrefix}: onConstraintsFound`, constraints);

      if (shouldStop()) {
        throw otError(Errors.CANCEL, new Error(GET_USER_ABORT_ERROR_MSG));
      }

      const IOS_NEW_WINDOW_BUG = 'Failed to acquire functional stream, this might be caused by ' +
          'the following iOS bug: https://bugs.webkit.org/show_bug.cgi?id=188088';

      const tryGetUserMedia = async () => {
        const gumRequest = getUserMediaHelper(constraints, isScreenSharing);
        gumRequest.on('accessDialogOpened', onAccessDialogOpened);
        gumRequest.on('accessDialogClosed', onAccessDialogClosed);

        try {
          /** @type {MediaStream} */
          const stream = await gumRequest;
          if (
            isiOS() && properties.publishVideo && stream.getVideoTracks().length &&
            stream.getVideoTracks()[0].readyState === 'ended'
          ) {
            stream.getTracks().forEach(track => track.stop());
            const error = new Error(IOS_NEW_WINDOW_BUG);
            throw error;
          }
          return stream;
        } catch (err) {
          if (isiOS() && err.name === Errors.HARDWARE_UNAVAILABLE) {
            throw new Error(IOS_NEW_WINDOW_BUG);
          }
          throw err;
        } finally {
          // Wait a little bit before we clean up the event handlers
          // to give 'accessDialogClosed' a chance to fire
          setTimeout(() => {
            gumRequest.off('accessDialogOpened', onAccessDialogOpened);
            gumRequest.off('accessDialogClosed', onAccessDialogClosed);
          });
        }
      };

      const stream = await promiseEndeavour(tryGetUserMedia, (err, attempt) => {
        if (err.message === IOS_NEW_WINDOW_BUG && attempt === 1 && !shouldStop()) {
          // Detected the iOS new window bug. Need try again.
          // https://tokbox.atlassian.net/browse/OPENTOK-37691
          // https://bugs.webkit.org/show_bug.cgi?id=188088
          // Fixed 4th Dec 2018 - unsure which Safari versions are fixed
          return 1000;
        }
        return false;
      })();

      if (shouldStop()) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        throw otError(Errors.CANCEL, new Error(GET_USER_ABORT_ERROR_MSG));
      }

      if (isCustomAudioTrack) {
        stream.addTrack(options.audioSource);
      }
      if (isCustomVideoTrack) {
        stream.addTrack(options.videoSource);
      }
      return stream;
    };

    return processedOptions;
  };
};
