const urlUtil = require('url');

const stripTrailingSlash = urlObj => `${urlObj.host}${urlObj.pathname}`.replace(/\/$/, '');

const prependProxyToUrlIfNeeded = (url, proxyUrl) => {
  if (!proxyUrl) return url;

  const proxyUrlParsed = urlUtil.parse(proxyUrl);
  const urlParsed = urlUtil.parse(url);

  if (!proxyUrlParsed.protocol || !urlParsed.protocol) return url;

  const isSsl = proxyUrlParsed.protocol.match(/^https/);
  const isWs = urlParsed.protocol.match(/^ws/i);

  const protocol = (isWs ? 'ws' : 'http') + (isSsl ? 's' : '');

  const targetUrl = `${protocol}://${stripTrailingSlash(proxyUrlParsed)}`;
  const targetPath = `${urlParsed.host}${urlParsed.pathname}${urlParsed.search || ''}`;

  return `${targetUrl}/${targetPath}`;
};

module.exports = { prependProxyToUrlIfNeeded };
