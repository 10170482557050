const toArray = require('lodash/toArray');
const getRtcStatsReportAdapter = require('./get_rtc_stats_report_adapter.js');
const shouldUseStandardGetStats = require('../../helpers/shouldUseStandardGetStats.js')();

// This returns the array-ified list of stats our users are accustomed to
module.exports = (peerConnection, completion) => {
  getRtcStatsReportAdapter(peerConnection, (err, rtcStatsReport) => {
    let stats = [];

    if (shouldUseStandardGetStats) {
      // Firefox <= 45 can't use rtcStatsReport.values OPENTOK-32755
      if (typeof rtcStatsReport.values === 'function') {
        stats = toArray(rtcStatsReport.values());
      } else if (typeof rtcStatsReport.forEach !== 'function') {
        stats = Object.keys(rtcStatsReport).map(key => rtcStatsReport[key]);
      } else {
        rtcStatsReport.forEach((rtcStats) => {
          stats.push(rtcStats);
        });
      }
    } else {
      rtcStatsReport.result().forEach((rtcStat) => {
        const stat = {};

        rtcStat.names().forEach((name) => {
          stat[name] = rtcStat.stat(name);
        });

        // fake the structure of the "new" RTC stat object
        stat.id = rtcStat.id;
        stat.type = rtcStat.type;
        stat.timestamp = rtcStat.timestamp;
        stats.push(stat);
      });
    }

    completion(null, stats);
  });
};
