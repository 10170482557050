const sessionObjects = require('./session/objects.js');
const ProxyUrlWarnings = require('./ProxyUrlWarnings');

let proxyUrl;

module.exports = {
  setProxyUrl: (url) => {
    const hasSessionInitalized = sessionObjects.sessions.length() > 0;
    const hasPublisherInitalized = sessionObjects.publishers.length() > 0;
    if (hasPublisherInitalized || hasSessionInitalized) {
      // @TODO WILL NEED TO UPDATE THESE console.warns TO EXCEPTIONS IN 2.18.0
      console.warn(ProxyUrlWarnings.TEARDOWN_REQUIRED);
    } else if (proxyUrl) {
      console.warn(ProxyUrlWarnings.ALREADY_SET);
    } else {
      // eslint-disable-next-line no-param-reassign
      proxyUrl = url;
    }
  },
  getProxyUrl: () => proxyUrl,

  // this method should only be exposed to tests
  clearProxyUrl: () => {
    proxyUrl = null;
  },
};
