/* eslint-disable global-require */

const once = require('lodash/once');
const StaticConfig = require('./StaticConfig')();

// Indicates whether this client supports WebRTC
//
// This is defined as: getUserMedia + PeerConnection + exceeds min browser version
//
exports.check = (deps = {}) => {
  const env = deps.env || require('../helpers/env.js');
  const hasPeerConnectionCapability = deps.hasPeerConnectionCapability || require('./hasPeerConnectionCapability.js');
  const logging = deps.logging || require('../helpers/log')('hasOpenTokSupport');
  /** @type StaticConfig */
  const staticConfig = deps.staticConfig || StaticConfig.onlyLocal();
  const minimumVersions = staticConfig.minimumVersion || {};
  const minimumVersion = minimumVersions[env.name.toLowerCase()];

  if (minimumVersion && minimumVersion > env.version) {
    logging.debug('Support for', env.name, 'is disabled because we require',
      minimumVersion, 'but this is', env.version);
    return false;
  }

  if (env.name === 'Node') {
    // Node works, even though it doesn't have getUserMedia
    return true;
  }

  return hasPeerConnectionCapability();
};

exports.once = once(() => exports.check());
